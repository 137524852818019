import React, { useState } from 'react';
import StandardPage from '../components/page/page-standard';
import '../components/faq-page/faq-page.scss';
import FaqList from '../components/faq-page/faq-list/faq-list';
import FaqSearch from '../components/faq-page/faq-search/faq-search';

const pageName = 'faq-page';

const FaqPage = () => {
  const LIST_CLASS = 'faq-page__list';

  const [value, setValue] = useState('');

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <StandardPage pageName={pageName} title="FAQ">
      <FaqSearch onChange={handleChange} value={value} />
      <FaqList value={value} className={LIST_CLASS} />
    </StandardPage>
  );
};

export default FaqPage;
