import React from 'react';
import PropTypes from 'prop-types';
import './faq-list.scss';
import { mergeClasses } from '../../../../libs/helpers';
import questions from '../../../__mocks__/faq';
import FaqItem from '../faq-item/faq-item';

const FaqList = (props) => {
  const { className, value } = props;

  const ROOT_CLASS = 'faq-list';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  // todo alex функция с душком. Мб вынести на компонент выше?
  const filterQuestion = questions.filter(({ title }) => title.toLowerCase().includes(value.toLowerCase()));

  return (
    <ul className={rootClass}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {questions && filterQuestion.map((question, key) => <FaqItem key={key} {...question} />)}
    </ul>
  );
};

FaqList.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
};

FaqList.defaultProps = {
  className: '',
  value: '',
};

export default FaqList;
