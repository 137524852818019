// todo alex запонить данные актуальными и полностью
import { lorem } from './typo';

export default [
  {
    title: 'Услуги, на которую я хочу записаться, нет в списке',
    answer: lorem,
  },
  {
    title: 'Как мне восстановить доступ к ЛК, если я сменил(а) номер телефона?',
    answer: '11111111111',
  },
  {
    title: 'Как расплатиться бонусами в салоне?',
    answer: '22222222222',
  },
  {
    title: 'Как сменить дату рождения животного в личном кабинете?',
    answer: '333333333333',
  },
  {
    title: 'Как воспользоваться подарком?',
    answer: lorem,
  },
  {
    title: 'Мне подарили виртуальный сертификат. Как им воспользоваться?',
    answer: lorem,
  },
  {
    title: 'Я не получил(а) бонусы за услуги',
    answer: lorem,
  },
];
