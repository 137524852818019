import React from 'react';
import PropTypes from 'prop-types';
import Search from '../../search/search';
import { mergeClasses } from '../../../../libs/helpers';
import './faq-search.scss';

const FaqSearch = (props) => {
  const { className, onChange } = props;

  const ROOT_CLASS = 'faq-search';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return <Search className={rootClass} onChange={onChange} placeholder="Поиск вопроса" />;
};

FaqSearch.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

FaqSearch.defaultProps = {
  className: '',
  onChange: () => {},
};

export default FaqSearch;
