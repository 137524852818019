import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SlideDown } from 'react-slidedown';
import { Arrow } from '../../icons/icons';

import 'react-slidedown/lib/slidedown.css';

import './faq-item.scss';
import { mergeClasses } from '../../../../libs/helpers';

const FaqItem = (props) => {
  const { className, title, answer } = props;


  const [isOpen, setOpen] = useState(false);

  const ROOT_CLASS = 'faq-list__item';
  const TITLE_CLASS = 'faq-list__title page__title page__title_h6';
  const ICON_CLASS = 'faq-list__icon';
  const TOP_CLASS = 'faq-list__top';
  const BODY_CLASS = 'faq-list__body';
  const ANSWER_CLASS = 'faq-list__answer page__limited-text';

  const iconClass = mergeClasses(ICON_CLASS);
  const bodyClass = mergeClasses(BODY_CLASS);
  const rootClass = mergeClasses(ROOT_CLASS, className);

  const Icon = Arrow.down;

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <li className={rootClass}>
      <button type="button" className={TOP_CLASS} onClick={handleClick}>
        <Icon className={iconClass} />
        <div className={TITLE_CLASS}>{title}</div>
      </button>
      <SlideDown className={bodyClass}>
        {isOpen ? <div className={ANSWER_CLASS}>{answer}</div> : null}
      </SlideDown>
    </li>
  );
};

FaqItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  answer: PropTypes.string,
};

FaqItem.defaultProps = {
  className: '',
  title: '',
  answer: '',
};

export default FaqItem;
