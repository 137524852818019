import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Search as Icon, X as CloseIcon } from '../icons/icons';
import { mergeClasses } from '../../../libs/helpers';
import './search.scss';

const Search = (props) => {
  const {
    className, onChange, placeholder, inputClass, iconClass, closeIconClass, value, onClear,
  } = props;

  const [valueSearch, setValue] = useState(value);

  const ROOT_CLASS = 'search';
  const WRAP_CLASS = `${ROOT_CLASS}__wrap`;

  const rootClass = mergeClasses(ROOT_CLASS, className);

  const INPUT_CLASS = `${ROOT_CLASS}__input`;
  const inputClassName = mergeClasses(INPUT_CLASS, inputClass);

  const ICON_CLASS = `${ROOT_CLASS}__icon`;
  const iconClassName = mergeClasses(ICON_CLASS, iconClass);

  const CLOSE_ICON_CLASS = `${ROOT_CLASS}__close-icon`;
  const closeIconClassName = mergeClasses(CLOSE_ICON_CLASS, closeIconClass);

  const handleChange = (e) => {
    onChange(e);
    setValue(e.target.value);
  };

  const handleClear = (e) => {
    onClear(e);
    setValue('');
  };

  return (
    <div className={rootClass}>
      <div className={WRAP_CLASS}>
        <Icon className={iconClassName} />
        <input
          type="text"
          onChange={handleChange}
          className={inputClassName}
          placeholder={placeholder}
          value={valueSearch}
        />
        {value !== '' && <CloseIcon onClick={handleClear} className={closeIconClassName} role="button" />}
      </div>
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  placeholder: PropTypes.string,
  inputClass: PropTypes.string,
  iconClass: PropTypes.string,
  closeIconClass: PropTypes.string,
  value: PropTypes.string,
};

Search.defaultProps = {
  className: '',
  onChange: () => {},
  onClear: () => {},
  placeholder: '',
  inputClass: '',
  iconClass: '',
  closeIconClass: '',
  value: '',
};

export default Search;
